<template>
  <div class="flex mb-6 info-item">
    <div class="label mr-4 opacity-8 text-md" :class="{'text-right':labelRight}" :style="{width: labelWidth + 'px'}">{{label}}：</div>
    <div class="flex-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    labelWidth: {
      type: String,
      default: '120'
    },
    labelRight: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>
.info-item
  line-height 32px
</style>