var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex mb-6 info-item" }, [
    _c(
      "div",
      {
        staticClass: "label mr-4 opacity-8 text-md",
        class: { "text-right": _vm.labelRight },
        style: { width: _vm.labelWidth + "px" },
      },
      [_vm._v(_vm._s(_vm.label) + "：")]
    ),
    _c("div", { staticClass: "flex-1" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }